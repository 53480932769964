import { Injectable } from '@angular/core'
import { pick } from 'lodash'
import { LogInResponse } from '../models/customer.models'
import { User } from '../models/local-storage.models'
import {
  LocalStorageService,
  SessionStorageService,
} from '@fleet-customer/shared/frontend/storage-services'

@Injectable()
export class AuthService {
  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService
  ) {}

  isAuthenticated(): boolean {
    return !!this.getAuthToken()
  }

  getAuthToken(): string {
    if (this.getImpersonatedById()) {
      return this.sessionStorageService.getItem('boosterAuthToken')
    }

    return this.localStorageService.getItem('boosterAuthToken')
  }

  getUserId(): string {
    if (this.getImpersonatedById()) {
      return this.sessionStorageService.getItem('boosterUserId')
    }

    return this.localStorageService.getItem('boosterUserId')
  }

  getUser() {
    return this.getImpersonatedById()
      ? this.sessionStorageService.getItem<User>('user')
      : this.localStorageService.getItem<User>('user')
  }

  getImpersonatedById(): string {
    return this.sessionStorageService.getItem('impersonatedById')
  }

  setAuthData({ boosterAuthToken, customer }: LogInResponse): void {
    this.localStorageService.setItem('boosterAuthToken', boosterAuthToken)
    this.localStorageService.setItem('boosterUserId', customer._id)
    // used for analytics
    const user = pick(customer, ['_id', 'firstName', 'lastName', 'email'])
    this.localStorageService.setItem('user', user)
  }

  clearAuthData(): void {
    this.localStorageService.clear()
    this.sessionStorageService.clear()
  }
}
